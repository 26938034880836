.settings-input-container {
  margin-top: 20px;
}
.setting-input {
  margin-top: 20px;
}
.setting-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}
.setting-btn {
  padding: 10px 100px;
}
.settings-pin-label {
  margin-bottom: 10px;
  color: #757575;
  font-size: 18px;
}
