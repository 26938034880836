.order-accordian-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 70px;
}
.order-accordian-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.order-accordian-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  background-color: rgba(128, 128, 128, 0.1);
  cursor: pointer;
  user-select: none;
}
.order-accordian-item.selected {
  background-color: rgb(26, 115, 232);
  color: white;
}
.order-accordian-item.left {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.order-accordian-item.right {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

/* Tablets (landscape) */
@media (min-width: 768px) and (max-width: 1080px) {
  .order-accordian-row {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .order-status-accordian-container {
    margin-top: 20px;
  }
}

/* Mobile devices */
@media (max-width: 767px) {
}
