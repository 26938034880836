.price-card {
  padding: 20px 20px;
}
.price-card-container {
  width: 22%;
}
.price-card-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.price-card-image {
  width: 80px;
  height: 80px;
}
.price-card-item-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.price-card-title {
  color: #9a9a9a;
  font-size: 18px;
}
.price-card-value {
  font-size: 24px;
  margin-top: 5px;
  font-family: ComicNeue, sans-serif;
  color: rgba(59, 60, 54, 1);
}
.price-card-desc {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;
  color: #9a9a9a;
}
.price-card-desc span {
  display: flex;
  margin-right: 5px;
  color: #66615b;
}
.trend-icon {
  margin-left: 5px;
}
.trend-icon.up {
  color: #22a079;
}
.trend-icon.down {
  color: #fb246a;
}

/* Tablets (landscape) */
@media (min-width: 768px) and (max-width: 1080px) {
  .price-card-container {
    width: 49%;
    margin-top: 20px;
  }
  .price-card-value {
    font-size: 14px;
  }
  .price-card-title {
    font-size: 14px;
  }
}

/* Mobile devices */
@media (max-width: 767px) {
  .price-card-container {
    width: 100%;
    margin-top: 20px;
  }
  .price-card-value {
    font-size: 20px;
  }
}
