.user-detail-layout-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.user-detail-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.user-detail-keys-container {
  width: 100%;
}
.user-detail-inputs-container {
  width: 100%;
  margin-top: 30px;
}
.user-detail-key-item {
  margin-top: 30px;
}
.user-detail-key-item-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.user-detail-key-item-title-img {
  width: 50px;
  height: 50px;
}
.user-detail-key-item-title-text {
  margin-left: 20px;
  font-size: 24px;
  color: #757575;
}
.key-pair-value {
  font-size: 8px;
}
.user-detail-key-pair-item {
  margin-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  /* overflow: hidden; */
  width: 100%;
  box-sizing: border-box;
}
.user-detail-key-pair-item-title {
  color: rgba(59, 60, 54, 0.8);
}
.user-detail-key-pair-item-value {
  margin-top: 10px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.user-detail-key-pair-item-value .desktop {
  display: flex;
}
.user-detail-key-pair-item-value .mobile {
  display: none;
}
.user-detail-key-pair-item-value span {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
}
.user-detail-profile-container {
  display: flex;
  flex-direction: row;
  position: relative;
  width: min-content;
}
.user-detail-profile-edit {
  position: absolute;
  background-color: dodgerblue;
  color: white;
  border-radius: 50%;
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.user-detail-profile-img-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}
.user-detail-images-container {
  justify-content: center;
}
.user-detail-title {
  font-size: 24px;
  color: rgba(59, 60, 54, 0.8);
}
.copy-icon:hover {
  color: #003687;
}

/* Tablets (landscape) */
@media (min-width: 768px) and (max-width: 1080px) {
  .key-value {
    font-size: 7px;
  }
  .user-detail-key-pair-item-value .desktop {
    display: none;
  }
  .user-detail-key-pair-item-value .mobile {
    display: flex;
  }
}

/* Mobile devices */
@media (max-width: 767px) {
  .key-value {
    font-size: 7px;
  }
  .user-detail-key-pair-item-value .desktop {
    display: none;
  }
  .user-detail-key-pair-item-value .mobile {
    display: flex;
  }
}
