.swap-items-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  font-family: ComicNeue, sans-serif;
}
.swap-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.swap-item-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(198, 213, 234, 0.9);
  color: white;
  border-radius: 50%;
  padding: 10px;
}
.swap-item-value {
  margin-top: 10px;
  color: rgba(198, 213, 234, 0.9);
}
.swap-item.completed .swap-item-icon-container {
  background-color: #005eff;
  color: white;
}
.swap-item.completed .swap-item-value {
  color: #22264c;
}
.swap-image-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.swap-icon-container {
  margin-right: 10px;
}
.swap-arrow-icon {
  width: 1.5em;
  height: 1.5em;
}

.swap-arrow-icon.in {
  color: #22a079;
}
.swap-arrow-icon.out {
  color: #fb246a;
}
