.settings-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.settings-card {
  width: 50%;
  padding: 15px;
}
.settings-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 32px;
  padding-bottom: 10px;
  color: #757575;
  border-bottom: 1px solid #7575;
}
.settings-title span {
  display: flex;
  margin-right: 10px;
}
.setting-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.settings-value {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.settings-value span {
  display: flex;
  margin-right: 10px;
}
.settings-value:hover {
  color: dodgerblue;
  cursor: pointer;
}

/* Tablets (landscape) */
@media (min-width: 768px) and (max-width: 1080px) {
  .settings-card {
    width: 80%;
  }
}

/* Mobile devices */
@media (max-width: 767px) {
}
