.buy-order-review-item {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}
.buy-order-review-item-title {
  color: rgba(59, 60, 54, 0.8);
}
.buy-order-review-value {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 15px;
  font-size: 18px;
  color: #22264c;
}
.buy-order-review-value span {
  display: flex;
}
.buy-order-review-title {
  color: #22264c;
  border-left: 5px solid #22264c;
  font-size: 32px;
  padding-left: 20px;
}
