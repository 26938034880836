.dashboard-navbar-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.dashboard-navbar-link-container {
  display: flex;
  flex-direction: column;
}
.dashboard-navbar-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgb(52, 71, 103);
}
.dashboard-navbar-link-icon {
  margin-right: 5px;
}
.dashboard-navbar-link-text {
  margin-left: 5px;
  color: rgb(52, 71, 103);
}
.dashboard-navbar-link-alt {
  color: rgb(52, 71, 103);
}
.dashboard-actions-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dashboard-navbar-action-item {
  display: flex;
  margin-right: 10px;
  color: rgb(123, 128, 154);
  cursor: pointer;
}
.dashboard-btn {
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
}
.dashboard-navbar-menu {
  margin-right: 20px;
}
.dashboard-navbar-menu svg {
  font-size: 40px;
  cursor: pointer;
  color: rgb(123, 128, 154);
}
/* Tablets (landscape) */
@media (min-width: 768px) and (max-width: 1080px) {
  .dashboard-navbar-container {
    align-items: flex-end;
  }
}

/* Mobile devices */
@media (max-width: 767px) {
  .dashboard-navbar-container {
    align-items: flex-end;
  }
}
