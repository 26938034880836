.profile-container {
  margin-top: 30px;
}
.profile-backgroundImage-container {
  position: relative;
  box-sizing: border-box;
}

.profile-backgroundImage-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 98%;
  background: linear-gradient(
    195deg,
    rgba(73, 163, 241, 0.6),
    rgba(26, 115, 232, 0.6)
  );
  border-radius: 20px;
  z-index: 1; /* Ensure the gradient overlay is on top of the image */
}

.profile-backgroundImage {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 20px;
  z-index: 0; /* Set a lower z-index to keep the image below the overlay */
}
.profile-user-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.profile-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.profile-info-text-container {
  margin-left: 20px;
}
.profile-card-container {
  position: relative;
  margin: 0px 50px;
  margin-top: -80px;
  z-index: 3;
}
.profile-card {
  background-color: white;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem,
    rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
  padding: 20px;
}
.profile-input-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
.profile-input-item {
  width: 49%;
}
.profile-input-btn-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}
.profile-btn {
  padding: 15px 40px;
  background-color: #014cbd;
  color: #fff;
}
.profile-images-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}
.profile-image-item {
  margin-left: 20px;
}
.profile-image {
  width: 80px;
  height: auto;
  border: 2px solid transparent;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}
.profile-user-container {
  position: relative;
}
.profile-user-edit {
  background-color: dodgerblue;
  color: white;
  border-radius: 50%;
  position: absolute;
  top: 25px;
  right: 0;
}
.profile-image-item.selected .profile-image {
  border: 2px solid dodgerblue;
}
.profile-user-edit svg {
  color: white;
  width: 0.5em;
  height: 0.5em;
}

@media (min-width: 768px) and (max-width: 1080px) {
}

/* Mobile devices */
@media (max-width: 767px) {
  .profile-input-row {
    flex-direction: column;
    margin-top: 0px;
  }
  .profile-input-item {
    width: 100%;
    margin-top: 20px;
  }
  .profile-card-container {
    margin: 0px;
  }
}
