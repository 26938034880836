.card-layout-container {
  margin-top: 20px;
}
.coinwallet-chart {
  width: 100%;
  height: 100%;
  margin-top: 20px;
}
.coinwallet-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.coinwallet-info-container {
  width: 35%;
}
.coinwallet-recieve-container {
  width: 20%;
}
.coinwallet-send-container {
  width: 35%;
}
.coinwallet-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.coinwallet-info-text-container {
  margin-left: 10px;
}
.coinwallet-img {
  width: 80px;
  height: 80px;
}

.coinwallet-info-price span {
  color: rgba(59, 60, 54, 0.8);
}
.coinwallet-info-balance span {
  color: rgba(59, 60, 54, 0.8);
}
.coinwallet-info-balance {
  margin-top: 10px;
}
.coinwallet-recieve-btn-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.coinwallet-recieve-btn {
  padding: 15px;
  width: 100%;
  margin-top: 5px;
}
.coinwallet-input-container {
  margin-top: 20px;
}
.coinwallet-send-title {
  font-size: 18px;
  font-family: ComicNeue, sans-serif;
  font-weight: bolder;
  margin-top: 20px;
}
.coinwallet-chart-title {
  font-size: 18px;
  font-family: ComicNeue, sans-serif;
}
.coinwallet-back-btn {
  margin-bottom: 20px;
}
.coinwallet-wallet-address-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.coinwallet-wallet-address-title {
  margin-top: 10px;
  font-size: 20px;
}
.coinwallet-wallet-address-value {
  margin-top: 10px;
  font-size: 14px;
}
/* Tablet */
@media (min-width: 0px) and (max-width: 1080px) {
  .coinwallet-container {
    flex-direction: column-reverse;
    justify-content: center;
  }
  .coinwallet-send-container {
    width: 100%;
  }
  .coinwallet-recieve-container {
    width: 100%;
    margin-top: 20px;
  }
  .coinwalllet-qr-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .coinwallet-info-container {
    width: 100%;
    margin-top: 20px;
  }
}
/* Mobile devices */
@media (max-width: 767px) {
}
