*{
    margin: 0;
 padding: 0;
 box-sizing: border-box;
}

.container{
    padding: 1.5rem 0;
}

.greenR{
    color:rgb(48, 204, 0);
    font-weight: 600;
}

.redR{
    color:red;
    font-weight: 600;
}

.card{
    border:2px solid rgba(0,0,0,.1);
    border-radius: .7rem;
    padding: 1.5rem 1.4rem;
    max-width: 28rem;
    margin: 0 auto 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.card h1 {
    font-weight: 400;
    font-size: 1.5rem;
}

.slate{
    color:#7A7D94;
    font-weight: 500;
}

.bigText{
    font-size:2rem;
    font-weight: 500;
}

.details{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.details a{
    color: #FC8F0F;
    text-decoration: none;
    font-size: 1.2rem;
}

.miners{
    border: 2px solid rgba(0,0,0,.1);
    border-radius: 10px;
    padding: 1rem;
    max-width: 28rem;
    margin: 0 auto;
}
.table{
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    overflow-x: scroll;
    position: relative;
}
th{
    font-size: 1rem;
    color: black;
    opacity: 1;
}
td{
    color: black;
    opacity: 1;
    font-size: 14px;
    font-weight: 600;
    position: relative;
}
tbody tr{
    border-bottom: 2px solid rgba(0,0,0,.1);
    padding: 1.4rem 0;
}
.dots{
    background-color: inherit;
    border: none;
    border-radius: 5px;
    padding: 6px;
}
.dots:hover{
    background-color: rgba(0,0,0,.1);

}
.blueR{
    color: #003687;
}
.edit{
    background-color: #014cbd;
    color: #fff;
    width: 7rem;
    height: fit-content;
    box-shadow: 0 0 4px 0 rgba(0,0,0,.3);
    border:none;
    /* position: absolute; */
    /* top: 2rem; */
    /* right: 4rem; */
    z-index: 10;
}
.edit div{
    padding: 7px 0;
    border-bottom: 1px solid rgba(0,0,0,.1);
    text-align: center;
    display: flex;
    justify-content: center;
}
.edit div:hover{
    background-color: rgba(0,0,0,.1);
}
.edit img{
    margin-right: 5px;
}
.miner-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}
.miner-header button{
    border:none;
    border-radius: 1rem;
    background-color: #003687;
    color: white;
    font-weight: 600;
    font-size: 1rem;
    padding: 0 1rem;
}
dialog{
    border:none;
    margin: auto;
    width: 80%;
    max-width: 24rem;
    height: 60vh;
    padding: 1rem 1.4rem;
}
.form label{
    display: block;
    color: #7A7D94;
}
label input{
    display: block;
    border: 2px solid rgba(0,0,0,.2);
    width: 100%;
    padding: 4px 1rem;
    outline: #2476f1;
    border-radius: 4px;
    margin: 2px 0 8px;
    color: black;
}
.rateD input,#active{
    display: inline-block;
}
.rateD input{
    width: 4rem;
    text-align: center;
    padding: 4px 0;
    margin-right: 5px;
    margin-left: 5px;
}
#active{
    width: fit-content;
    margin-right: 8px;
    margin-top: 1rem;
}
.form img{
    display: inline;
}
dialog>div{
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;
    font-weight: 700;
    color: #003687;
}
dialog>div>p{
    width: 100%;
    text-align: center;
    padding-left: 4px;
}

#prof,#dur{
    position: relative;
}
#prof img,#dur img{
    position: absolute;
    left: 14px;
    bottom: 10px;
}
#profit,#duration{
    padding-left: 2.2rem;
}
#act{
    color: black;
}
.buttons{
    display: flex;
    justify-content: space-around;
}
.buttons button{
    width: 40%;
    border-radius: 8px;
    padding: 6px 0;
    margin-top: 28px;
}
#cancel{
    background-color: #E5EFFF;
    color: #005EFF;
}
#submit{
    background-color: #003687;
    color: white;
}
.miner-header h1{
    font-weight: 700;
    font-size: 20px;
}
@media (min-width: 768px) {
    .cards{
        display: flex;
        justify-content: flex-start;
        gap: 1rem;
    }
    .card{
        max-width: 25rem;
        margin: 0;
        width: 60%;
    }
    .miners{
        width: 100%;
        max-width: none;
        font-size: larger;
        margin-top: 2rem;
    }
    .miner-header h1{
        font-weight: 700;
        font-size: 1.5rem;
    }
    .miner-header>button{
        height: fit-content;
        padding: 13px 2rem ;
        margin: auto 0;
    }
}