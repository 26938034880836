.coincard-container {
  width: 30%;
}
.coincard-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.coin-card {
  padding: 20px;
}
.coincard-balance {
  font-size: 24px;
  color: rgba(59, 60, 54, 1);
  font-family: ComicNeue, sans-serif;
  margin-bottom: 10px;
}
.coincard-diff-percet {
  color: white;
  padding: 5px 10px;
  border-radius: 25px;
}
.coincard-actions {
  width: 100%;
}
.coincard-details {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.coincard-btn {
  width: 100%;
  padding: 20px 10px;
}
.coincard-diff-percet.positive {
  background-color: #6bd098;
}

.coincard-diff-percet.negative {
  background-color: #fb246a;
}
/* Tablets (landscape) */
@media (min-width: 768px) and (max-width: 1080px) {
  .coincard-container {
    width: 100%;
    margin-top: 20px;
  }
}
/* Mobile devices */
@media (max-width: 767px) {
  .coincard-container {
    width: 100%;
    margin-top: 20px;
  }
}