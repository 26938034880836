.review-transaction-details {
  width: 65%;
  padding: 50px;
}
.review-transaction-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}
.border-bottom-none {
  border-bottom: none !important;
}
.review-transaction-value {
  margin-left: 30px;
}
.review-transaction-title {
  color: rgba(59, 60, 54, 0.8);
}
.review-btn-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}
.review-info {
  margin-bottom: 20px;
}
@media (min-width: 768px) and (max-width: 1080px) {
  .review-transaction-item {
    font-size: 14px;
  }
  .review-transaction-details {
    padding: 0px;
    width: 100%;
  }
}

/* Mobile devices */
@media (max-width: 767px) {
  .review-transaction-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 13px;
  }
  .review-transaction-details {
    padding: 0px;
    width: 100%;
  }
  .review-transaction-value {
    margin-top: 10px;
    margin-left: 0px;
  }
}
