.digits-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.digit-input {
  width: 60px;
  height: 60px;
  font-size: 40px;
  text-align: center;
  margin-right: 20px;
}
.digit-icon-container {
  cursor: pointer;
}
@media (min-width: 768px) and (max-width: 1080px) {

}

/* Mobile devices */
@media (max-width: 767px) {
  .digit-input {
    width: 40px;
    height: 40px;
    font-size: 30px;
  }
}
