.swaps-layout-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.swap-title {
  font-size: 24px;
  font-family: ComicNeue, sans-serif;
}
.swap-card {
  width: 50%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
}
.swap-inputs-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.swap-input-item {
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
  width: 100%;
}
.swap-intput {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.swap-input-textfield {
  font-size: 24px;
  width: 70%;
}
.swap-input-dropdown {
  width: 30%;
  font-size: 24px;
}

.swap-range-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  color: rgba(36, 37, 43, 0.6);
  font-family: ComicNeue, sans-serif;
}
.swap-input-balance-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5px;
}
.swap-input-balance {
  color: rgba(36, 37, 43, 0.6);
  font-family: ComicNeue, sans-serif;
}
.swap-btn-container {
  width: 100%;
}
.swap-btn {
  width: 100%;
  padding: 15px;
}

@media (min-width: 768px) and (max-width: 1080px) {
  .swap-card {
    width: 100%;
    margin-top: 50px;
  }
}

/* Mobile devices */
@media (max-width: 767px) {
  .swap-card {
    width: 100%;
    margin: 50px 0px;
    padding: 20px;
  }
  .swap-intput {
    flex-direction: column-reverse;
  }
  .swap-input-dropdown {
    width: 100%;
  }
  .swap-input-textfield {
    width: 100%;
  }
  .swap-range-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .swap-range-item {
    margin: 10px 0px;
  }
}
