.sidebar-container {
  background: rgba(0, 0, 0, 0)
    linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25)) repeat scroll 0%
    0%;
  position: fixed;
  width: 18%;
  left: 0%;
  padding: 20px;
  color: white;
  margin: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  height: 95vh;
  transition: all 1s ease-in-out;
}
.sidebar-container.open {
  left: 0%;
}
.sidebar-container.close {
  left: -20%;
}
.sidebar-header-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.sidebar-header-title span {
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.sidebar-items-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.sidebar-item {
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
}
.sidebar-item span {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
}
.sidebar-item.selected {
  background: rgba(0, 0, 0, 0)
    linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232)) repeat scroll
    0% 0%;
}
.sidebar-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.navbar-sidebar-icon {
  margin-left: 20px;
  display: none;
}
.sidebar-header-title .menu-icon-container {
  display: none;
  margin-left: 10px;
  cursor: pointer;
}
/* Tablets (landscape) */
@media (min-width: 768px) and (max-width: 1080px) {
  .sidebar-container.open {
    left: 0%;
    width: 25%;
    left: 0%;
  }
  .sidebar-container.close {
    left: -30%;
  }
}

/* Mobile devices */
@media (max-width: 767px) {
  .sidebar-container.open {
    width: 80%;
    left: 0%;
  }
  .sidebar-container.close {
    left: -100%;
  }
  .navbar-sidebar-icon {
    display: flex;
  }
  .menu-icon-container svg {
    font-size: 40px;
    cursor: pointer;
    color: rgb(123, 128, 154);
  }
  .sidebar-header-title .menu-icon-container {
    display: flex;
  }
}
