.loader-container {
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
  border-radius: 0.75rem;
}
.showLoader {
  display: flex;
}
.loader-text {
  font-size: 24px;
  color: #22264c;
  margin-top: 10px;
  font-weight: bolder;
  user-select: none;
}
.block {
  color: #22264c !important;
}
