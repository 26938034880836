.review-order-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.review-order-user-container {
  width: 50%;
  box-sizing: border-box;
  padding: 0px 75px;
}
.review-order-info-container {
  width: 50%;
}
.review-order-user-img-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.review-order-btn-container {
  margin-top: 20px;
}
.review-order-btn {
  padding: 10px 100px;
  margin-right: 20px;
}
.review-order-btn.reject {
  background-color: #fb246a;
}
.review-order-btn.completed {
  background-color: #22a079;
}
.buy-order-review-value-text {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.crypto-sent-img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.reason-textarea-container {
  width: 100%;
  margin-top: 10px;
}
.reason-textarea {
  width: 100%;
}
.reason-switch-container-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.reason-switch-container-label {
  margin-right: 10px;
  color: rgba(59, 60, 54, 0.8);
}
