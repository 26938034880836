@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "ComicNeue";
  src: url(./fonts/ComicNeue.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto";
  src: url(./fonts/Roboto.ttf) format("truetype");
}

#root,
body {
  margin: 0px;
  padding: 0px;
  font-family: sans-serif;
  background-color: rgb(240, 242, 245);
  font-family: Roboto, sans-serif;
  overflow-x: hidden;
}
.input {
  width: 100%;
}
.w-100 {
  width: 100%;
}
.continue {
  width: 500px;
  max-width: 100%;
  padding: 10px;
}
.wallet-price-containers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 30px;
}
.yellow {
  background-color: #f7931a;
}
.yellow:hover {
  background-color: rgba(247, 147, 26, 0.8);
}
.green {
  background: #22a079;
}
.green:hover {
  background: rgba(34, 160, 121, 0.8);
}

.blue {
  background-color: #005eff;
}
.blue:hover {
  background-color: rgba(0, 94, 255, 0.8);
}

/* Table */
.table-layout-container {
  width: 100%;
  box-sizing: border-box;
  padding: 0px;
  margin-top: 80px;
  position: relative;
}
.table-container {
  overflow-x: auto;
  margin: 20px;
  padding-top: 25px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  text-align: left;
  padding: 8px;
  vertical-align: middle; /* Align content vertically in the middle of cells */
  border-bottom: 0.0625rem solid rgb(240, 242, 245);
}
td {
  color: rgb(52, 71, 103);
}

th {
  position: relative;
  text-align: left;
  opacity: 0.7;
  background: transparent none repeat scroll 0% 0%;
  color: rgb(123, 128, 154);
  box-shadow: none;
  font-size: 0.65rem;
  font-weight: 700;
  text-transform: uppercase;
}

.user-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.tenant-user-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.table-title {
  padding: 24px 16px;
  opacity: 1;
  background: rgba(0, 0, 0, 0)
    linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232)) repeat scroll
    0% 0%;
  color: rgb(52, 71, 103);
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem,
    rgba(0, 187, 212, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
  color: white;
  position: absolute;
  width: 96%;
  box-sizing: border-box;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: -50px;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.no-transaction-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
}
.no-transaction-img {
  width: 300px;
  height: auto;
}
.no-transaction-text {
  font-size: 24px;
  font-weight: bolder;
}
.copy-icon-container {
  cursor: pointer;
}

.search-input .MuiInput-underline {
  color: white !important;
  border-bottom-color: white !important;
}

.search-input .MuiInput-underline::before {
  border-bottom-color: white !important;
}

.search-input .MuiInput-underline::after {
  border-bottom-color: whitesmoke !important;
}

.search-input .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom-color: #fcfbfc !important;
}

.search-input .MuiInput-input {
  color: white !important;
}
td.with-icon {
  /* background-color: #22a079;
  color: white;
  padding: 10px;
  border-radius: 50%; */
  /* text-align: center; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.td-icon-container {
  padding: 5px;
  color: white;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.td-icon-container.send {
  background-color: #fb246a;
}
.td-icon-container.receive {
  background-color: #22a079;
}
.table-icon {
  width: 0.7em;
  height: 0.7em;
}

.td-icon-text.icon-left {
  margin-left: 10px;
}
.td-icon-text.icon-right {
  margin-right: 10px;
}
.td-icon-container.new-tab {
  background-color: #005eff;
}
.color-white {
  color: white;
}

@media (min-width: 768px) and (max-width: 1080px) {
  table {
    width: 200%;
  }
  .wallet-price-containers {
    flex-wrap: wrap;
  }
}

/* Mobile devices */
@media (max-width: 767px) {
  table {
    width: 300%;
  }
  .table-title {
    flex-direction: column-reverse;
    margin-top: -80px;
  }
  .table-title span {
    margin-top: 20px;
  }
  .wallet-price-containers {
    flex-wrap: wrap;
  }
  .table-layout-container {
    margin-top: 110px;
  }
}
