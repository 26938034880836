.wallet-coincards-container {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 768px) and (max-width: 1080px) {
  .wallet-coincards-container {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
/* Mobile devices */
@media (max-width: 767px) {
  .wallet-coincards-container {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}