.coinfirm-pin-details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.confirm-pin-name {
  margin-top: 10px;
  font-size: 32px;
}
.confirm-pin-email {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 18px;
}
.confirm-pin-note {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  padding: 15px;
  border-radius: 5px;
  margin-top: 20px;
  box-sizing: border-box;
}
.confirm-pin-note span {
  font-weight: bolder;
}
.confirm-pin-btn {
  margin-top: 20px;
}

@media (min-width: 768px) and (max-width: 1080px) {
}

/* Mobile devices */
@media (max-width: 767px) {
  .confirm-pin-note {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    width: 100%;
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
  }
}
